<template>
  <v-dialog :retain-focus="false" v-model="dialog" fullscreen persistent transition="dialog-bottom-transition">
    <v-card style="padding-bottom: 60px!important;">
      <v-toolbar light>
        <v-btn icon light @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editare produs de baza</v-toolbar-title>
      </v-toolbar>

      <loader :loading="loading"/>
      <v-card-text>
        <v-sheet v-show="stage==='form'">
          <v-row>
            <v-col cols="6" sm="4">
              <v-select
                v-model="category_id"
                :items="categories"
                clearable
                disabled
                item-text="name"
                item-value="id"
                label="Categorie"
              />
              <error-messages :errors="errors.category_id"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field v-model="name" class="w-full" label="Nume" placeholder="eg. Dell E403"/>
              <error-messages :errors="errors.name"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="internal_name" class="w-full" label="Nume intern" placeholder="eg. Dell E403"/>
              <error-messages :errors="errors.internal_name"/>
            </v-col>
            <v-col cols="12" sm="3">
              <disableable-text-field v-model="model" class="w-full" label="Model" placeholder="eg. E403"/>
              <error-messages :errors="errors.model"/>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="flex">
                <div class="flex-1">
                  <brand-selector v-model="brand_id"/>
                  <error-messages :errors="errors.brand_id"/>
                </div>
                <div>
                  <add-new-brand-dialog/>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <disableable-text-field
                v-model="price"
                class="w-full"
                label="Pret vanzare (fara TVA)"
                placeholder="eg. 100"
                type="number"/>
              <error-messages :errors="errors.price"/>
            </v-col>
            <v-col>
              <disableable-text-field
                v-model="buying_price"
                class="w-full"
                label="Pret Achizitie (fara TVA)"
                placeholder="eg. 100"
                type="number"/>
              <error-messages :errors="errors.buying_price"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <disableable-text-field
                v-model="stock"
                class="w-full"
                label="Stoc"
                placeholder="eg. 10"
                type="number"/>
              <error-messages :errors="errors.stock"/>
            </v-col>
            <v-col>
              <div class="flex">
                <v-select
                  v-model="stock_status" :items="statuses"
                  class="w-full"
                  item-text="value" item-value="id"
                  label="Status stoc"/>
                <help-icon position="left"
                           text="Categoria de stoc nu influenteaza vizibilitatea si posibilitatea de a fi achizitionate."/>
              </div>
              <error-messages :errors="errors.stock_status"/>
              <div v-if="stock_status ==='to-be-received'">
                <date-picker v-model="receiving_at"/>
                <error-messages :errors="errors.receiving_at"/>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <bp-description v-model="description">
                <error-messages :errors="errors.description"/>
              </bp-description>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <div class="flex">
                      <span class="image-upload-label mr-2">
                        Imagine principala
                      </span>
                    <help-icon text="Imaginea care va fi afisata in toate listarile si prima din galerie"/>
                  </div>
                  <bp-product-images-uploader
                    ref="featuredImageUploader"
                    :existing="existingFeaturedImage"
                    single
                    text="Alegeti imaginea principala"
                    @input="featuredImageUpdated"
                  />
                  <error-messages :errors="errors.featured_image"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="flex">
                      <span class="image-upload-label mr-2">
                        Imagini Galerie
                      </span>
                    <help-icon text="Nu este necesara incarcarea imaginii principale. Aceasta va fi preluata automat"/>
                  </div>
                  <bp-product-images-uploader
                    ref="imageUploader"
                    :existing="this.existingGallery"
                    text="Alegeti imagini pentru galeria produsului"
                    @input="galleryUpdated"
                  />
                  <error-messages :errors="errors.images"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-show="stage==='attributes'">
          <base-product-attribute-manager ref="attributeManager" :base-product="baseProduct"/>
        </v-sheet>
      </v-card-text>
    </v-card>
    <v-toolbar class="fixed bottom-0 z-20" color="primary" flat>
      <v-btn dark text @click="close">Inchide</v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-if="stage==='form'" color="#30650a" dark @click="create">
          Salveaza si mergi mai departe
          <v-icon class="ml-2">fad fa-arrow-alt-from-left</v-icon>
        </v-btn>
        <v-btn v-if="stage==='attributes'" color="#30650a" dark @click="saveAttributes">
          Salveaza
          <v-icon class="ml-2">fad fa-arrow-alt-from-left</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import Loader from '@/components/Loader'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import HelpIcon from '@/components/HelpIcon'
import BpDescription from '@/views/ProductManagement/BaseProductManager/components/BpDescription'
import DisableableTextField from '@/components/general-form/DisableableTextField'
import BrandSelector from '@/components/autocompletes/BrandSelector'
import AddNewBrandDialog from '@/views/ProductManagement/Brands/Create'
import ImageUploader from '@/components/general-form/ImageUploader'
import BpProductImagesUploader from '@/views/ProductManagement/BaseProductManager/components/BpProductImagesUploader'
import BaseProductAttributeManager from '@/views/ProductManagement/BaseProductManager/BaseProductAttributeManager'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  name: 'BaseProductEditor',
  components: {
    DatePicker,
    BaseProductAttributeManager,
    BpProductImagesUploader,
    ImageUploader,
    AddNewBrandDialog,
    BrandSelector,
    DisableableTextField,
    BpDescription,
    HelpIcon,
    ErrorMessages,
    Loader,
    NewButton
  },
  props: {
    activatorIsOnlyIcon: {
      type: Boolean,
      default: false
    },
    activatorColor: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      loading: false,
      stage: 'form',
      errors: {},
      categories: [],
      baseProduct: false,
      existingGallery: [],
      existingFeaturedImage: ''
    }
  },
  computed: {
    dialog () {
      return this.$store.getters['baseProductEditor/modalStatus']
    },
    statuses () {
      return this.$store.getters['general/productStockStatuses']
    },
    category_id: {
      get () {
        return this.$store.getters['baseProductEditor/category_id']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'category_id',
          value
        })
      }
    },
    name: {
      get () {
        return this.$store.getters['baseProductEditor/name']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'name',
          value
        })
      }
    },
    internal_name: {
      get () {
        return this.$store.getters['baseProductEditor/internal_name']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'internal_name',
          value
        })
      }
    },
    model: {
      get () {
        return this.$store.getters['baseProductEditor/model']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'model',
          value
        })
      }
    },
    receiving_at: {
      get () {
        return this.$store.getters['baseProductEditor/receiving_at']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'receiving_at',
          value
        })
      }
    },
    price: {
      get () {
        return this.$store.getters['baseProductEditor/price']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'price',
          value
        })
      }
    },
    buying_price: {
      get () {
        return this.$store.getters['baseProductEditor/buying_price']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'buying_price',
          value
        })
      }
    },
    stock: {
      get () {
        return this.$store.getters['baseProductEditor/stock']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'stock',
          value
        })
      }
    },
    stock_status: {
      get () {
        return this.$store.getters['baseProductEditor/stock_status']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'stock_status',
          value
        })
      }
    },
    featured_image: {
      get () {
        return this.$store.getters['baseProductEditor/featured_image']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'featured_image',
          value
        })
      }
    },
    images: {
      get () {
        return this.$store.getters['baseProductEditor/images']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'images',
          value
        })
      }
    },
    brand_id: {
      get () {
        return this.$store.getters['baseProductEditor/brand_id']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'brand_id',
          value
        })
      }
    },
    description: {
      get () {
        return this.$store.getters['baseProductEditor/description']
      },
      set (value) {
        this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
          key: 'description',
          value
        })
      }
    },
    form () {
      return this.$store.getters['baseProductEditor/getItemForEdit']
    }
  },
  methods: {
    nextStage () {
      this.stage = 'attributes'
    },
    async create () {
      this.loading = true
      try {
        const {data} = await this.$http.patch(`base-products/${this.form.id}`, this.form)
        this.baseProduct = data
        this.nextStage()
      } catch ({response}) {
        if (response.status === 422) {
          this.errors = response.data.errors
          this.$vs.notify({
            title: 'Eroare',
            text: 'O serie de campuri au erori de validare. Vă rugăm verificați!',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        }
      }
      this.loading = false
    },
    featuredImageUpdated (item) {
      let featured_image = false
      if (item.length > 0) {
        if (item[0].id) {
          featured_image = item[0].id
        } else if (item[0].upload.data) {
          featured_image = item[0].upload.data.id
        }
      }
      this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
        key: 'featured_image',
        value: featured_image
      })
    },
    galleryUpdated (items) {
      let images = []
      if (items.length > 0) {
        images = items.filter(item => (!!item.id || !!item.upload.data)).map((item) => {
          if (item.id) {
            return item.id
          } else if (item.upload.data) {
            return item.upload.data.id
          }
        })
      }
      this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
        key: 'images',
        value: images
      })
    },
    close () {
      this.stage = 'form'
      this.$store.dispatch('baseProductEditor/closeDialog')
      this.$store.commit('baseProductEditor/SET_EDIT_ITEM', {})
    },
    loadCategories () {
      return this.$http.get('/product-categories/parents-only')
        .then(({data}) => {
          this.categories = data
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la incarcarea categoriilor! va rugam incercati mai tarziu',
            color: 'danger'
          })
        })

    },
    async saveAttributes () {
      const baseProduct = await this.$refs.attributeManager.save()
      this.$emit('edited', baseProduct)
      this.stage = 'form'
      this.close()
    }
  },
  created () {
    this.loadCategories()
  },
  watch: {
    dialog (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.existingFeaturedImage = JSON.parse(JSON.stringify(this.featured_image))
        this.existingGallery = JSON.parse(JSON.stringify(this.images.filter(item => {
          return !item.is_featured_image
        })))
      }
    }
  }
}
</script>

