<template>
  <div>
    <file-pond
      ref="pond"
      :allow-reorder="true"
      :label-idle="labelIdle"
      :server="server"
      accepted-file-types="image/jpeg, image/png, image/webp"
      credits=""
      name="image"
      @processfile="processFile"
      @removefile="removeFile"
    />
    <span>Dimensiune maxima acceptata: 1MB <br>
      <i>Pentru buna functionare numele imaginilor nu trebuie sa contina spatii sau caractere speciale (ex nume corect: nume-imagine-corect)</i>
    </span>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  name: 'ImageUploader',
  components: {FilePond},
  props: {
    value:{},
    labelIdle: {
      default: 'Incarcare imagine'
    }
  },
  data () {
    return {
      actionUrl: '',
      actualFile: false
    }
  },
  methods: {
    processFile (error, file) {
      if (!error) {
        this.actualFile = file.serverId
      } else {
        this.actualFile = false
      }
    },
    removeFile () {
      this.actualFile = false
    },
    clearImages () {
      this.$refs.pond.removeFiles()
    }
  },
  computed: {
    server () {
      return {
        url: this.actionUrl,
        process: {
          headers: {
            'Accept': 'application/json',
            'Language': 'ro',
            'Authorization': `Bearer ${this.token}`
          }
        },
        revert: {
          headers: {
            'Accept': 'application/json',
            'Language': 'ro',
            'Authorization': `Bearer ${this.token}`
          }
        }
      }
    },
    token () {
      return this.$store.getters['auth/token']
    }
  },
  watch: {
    actualFile: {
      deep: true,
      handler () {
        const img = JSON.parse(this.actualFile)
        this.$emit('input', img.id)
      }
    }
  },
  created () {
    const baseURL = process.env.VUE_APP_BASE_URL_API
    this.actionUrl = `${baseURL}/app/uploader/image`
  }
}
</script>
