<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="base-products"
    >

      <template v-slot:item.stock_status="{item}">
        {{ statusLabel(item.stock_status) }}
      </template>

      <template v-slot:actions="{table}">
        <base-product-creator @added="table.loadItems"/>
        <base-product-editor @edited="table.loadItems"/>
      </template>

      <template v-slot:item.actions="{item}">
        <div v-if="!item.external_provider" class="d-flex items-center">

          <base-product-editor-trigger :base-product-id="item.id"/>
          <base-product-technical-doc :base-product-id="item.id"/>
          <base-product-gifts :base-product="item"/>

          <action-delete :action-url="`base-products/${item.id}`"/>

        </div>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import BaseProductEditorTrigger from '@/views/ProductManagement/BaseProductManager/BaseProductEditorTrigger'
import BaseProductTechnicalDoc from '@/views/ProductManagement/BaseProductManager/BaseProductTechnicalDoc'
import BaseProductGifts from '@/views/ProductManagement/BaseProductManager/BaseProductGifts'
import BaseProductCreator from '@/views/ProductManagement/BaseProductManager/BaseProductCreator'
import BaseProductEditor from '@/views/ProductManagement/BaseProductManager/BaseProductEditor'

export default {
  name: 'BaseProduct',
  components: {
    BaseProductEditor,
    BaseProductCreator,
    BaseProductGifts,
    BaseProductTechnicalDoc,
    BaseProductEditorTrigger,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          width: '120',
          sortable: true,
          filterType: 'text'
        },
        {
          text: 'Img',
          value: 'featured_image',
          display: 'image',
          width: '50',
          sortable: false
        },
        {
          text: 'Categorie',
          value: 'category.name',
          filterType: 'main-product-categories',
          filterName: 'category_id',
          width: '180',
          sortable: false
        },
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          width: '250',
          sortable: true
        },
        {
          text: 'Nume intern',
          value: 'internal_name',
          filterType: 'text',
          width: '250',
          sortable: true
        },
        {
          text: 'Brand',
          value: 'brand.name',
          filterType: 'brand',
          filterName: 'brand_id',
          width: '150',
          sortable: false
        },
        {
          text: 'Model',
          value: 'model',
          width: '150',
          filterType: 'text',
          sortable: false
        },
        {
          text: 'Stoc',
          value: 'stock',
          sortable: true
        },
        {
          text: 'Status',
          value: 'stock_status',
          width: '100',
          sortable: false
        }
      ]
    }
  },
  methods: {
    statusLabel (statusKey) {
      return this.keyedProductStatuses[statusKey]
    }
  },
  computed: {
    keyedProductStatuses () {
      return this.$store.getters['general/productStatuses']
    }
  }
}
</script>
