<template>
  <v-autocomplete
    v-model="selectOption"
    :dense="dense"
    :items="products"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :outlined="outlined"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    clearable
    hide-details
    hide-no-data
    item-text="full_name"
    item-value="id"
    no-filter
  >
    <template v-slot:item="{item}">
      <table width="100%">
        <tr>
          <td v-if="!hideId" width="5%"><strong style="margin-right: 5px">{{ item.product_id }}</strong></td>
          <td width="65%">{{ item.full_name }}</td>
          <td v-if="!hidePrice" width="10%"><strong>Pret:</strong><br/> {{ item.final_price }} lei</td>
          <td v-if="!hideDiscounts" width="10%"><strong>Discount EU:</strong><br/> {{ item.discount_end_users }}</td>
          <td v-if="!hideDiscounts" width="10%"><strong>Discount DIST:</strong><br/> {{ item.discount_resellers }}</td>
        </tr>
      </table>
    </template>
    <template v-slot:selection="{item}">
      <div>
        <template v-if="!actionInsteadOfSelected">
          <strong style="margin-right: 5px">{{ item.product_id }}:</strong> {{ item.full_name }}
        </template>
        <template v-else>
          <span>Click pentru a cauta un produs</span>
        </template>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ProductSelector',
  props: {
    status: {
      default: false
    },
    value: {},
    multiple: {
      default: false
    },
    label: {
      default: 'Produs'
    },
    returnObject: {
      default: false
    },
    actionInsteadOfSelected: {
      default: false,
      type: Boolean
    },
    forClient: {
      default: false
    },
    hideDiscounts: {
      default: false,
      type: Boolean
    },
    hidePrice: {
      default: false,
      type: Boolean
    },
    hideId: {
      default: false,
      type: Boolean
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginatedProducts: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    products () {
      return this.paginatedProducts.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      let extraQuery = ''
      if (this.status !== false) {
        extraQuery = `&filter[stock_value]=${this.status}`
      }

      if (this.forClient !== false) {
        extraQuery = `&for-client=${this.forClient}`
      }
      this.$http.get(`/products?filter[full_name]=${search}${extraQuery}`)
        .then(({data}) => {
          this.paginatedProducts = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
