<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="290px"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        v-bind="attrs"
        v-on="on"
        label="Data estimata receptie"
        persistent-hint
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: 'DatePicker',
  props: {
    value: {}
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    date: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.replace(' 00:00:00', '').split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>
