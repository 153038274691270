<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">

    <template v-slot:activator="{ on, attrs }">
      <new-button
          v-bind="attrs"
          v-on="on"
          :button-label="activatorIsOnlyIcon ? '' : 'Adauga Produs de baza'"
      />
    </template>
    <v-card style="padding-bottom: 60px!important;">
      <v-toolbar light>
        <v-btn icon light @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Adauga produs de baza</v-toolbar-title>
      </v-toolbar>

      <loader :loading="loading"/>
      <v-card-text>
        <v-sheet v-show="stage==='form'">
          <v-row>
            <v-col cols="6" sm="4">
              <v-select
                  v-model="form.category_id"
                  :disabled="!!forCategory"
                  :items="categories"
                  clearable
                  item-text="name"
                  item-value="id"
                  label="Categorie"
              />
              <error-messages :errors="errors.category_id"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field v-model="form.name" class="w-full" label="Nume" placeholder="eg. Dell E40"/>
              <error-messages :errors="errors.name"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="form.internal_name" class="w-full" label="Nume intern" placeholder="eg. Dell E40"/>
              <error-messages :errors="errors.internal_name"/>
            </v-col>
            <v-col cols="12" sm="3">
              <disableable-text-field v-model="form.model" class="w-full" label="Model" placeholder="eg. E40"/>
              <error-messages :errors="errors.model"/>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="flex">
                <div class="flex-1">
                  <brand-selector v-model="form.brand_id"/>
                  <error-messages :errors="errors.brand_id"/>
                </div>
                <div>
                  <add-new-brand-dialog/>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <disableable-text-field
                  v-model="form.price"
                  class="w-full"
                  label="Pret vanzare (fara TVA)"
                  placeholder="eg. 100"
                  type="number"/>
              <error-messages :errors="errors.price"/>
            </v-col>
            <v-col>
              <disableable-text-field
                  v-model="form.buying_price"
                  class="w-full"
                  label="Pret Achizitie (fara TVA)"
                  placeholder="eg. 100"
                  type="number"/>
              <error-messages :errors="errors.buying_price"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <disableable-text-field
                  v-model="form.stock"
                  class="w-full"
                  label="Stoc"
                  placeholder="eg. 10"
                  type="number"/>
              <error-messages :errors="errors.stock"/>
            </v-col>
            <v-col>
              <div class="flex">
                <v-select
                    v-model="form.stock_status" :items="statuses"
                    class="w-full"
                    item-text="value" item-value="id"
                    label="Status stoc"/>
                <help-icon position="left"
                           text="Categoria de stoc nu influenteaza vizibilitatea si posibilitatea de a fi achizitionate."/>
              </div>
              <error-messages :errors="errors.stock_status"/>

              <div v-if="form.stock_status ==='to-be-received'">
                <date-picker v-model="form.receiving_at"/>
                <error-messages :errors="errors.receiving_at"/>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <bp-description v-model="form.description">
                <error-messages :errors="errors.description"/>
              </bp-description>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <div class="flex">
                      <span class="image-upload-label mr-2">
                        Imagine principala
                      </span>
                    <help-icon text="Imaginea care va fi afisata in toate listarile si prima din galerie"/>
                  </div>
                  <bp-product-images-uploader
                      ref="featuredImageUploader"
                      single
                      text="Alegeti imaginea principala"
                      @input="featuredImageUpdated"
                  />
                  <error-messages :errors="errors.featured_image"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="flex">
                      <span class="image-upload-label mr-2">
                        Imagini Galerie
                      </span>
                    <help-icon text="Nu este necesara incarcarea imaginii principale. Aceasta va fi preluata automat"/>
                  </div>
                  <bp-product-images-uploader
                      ref="imageUploader"
                      text="Alegeti imagini pentru galeria produsului"
                      @input="galleryUpdated"
                  />
                  <error-messages :errors="errors.images"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-show="stage==='attributes'">
          <base-product-attribute-manager ref="attributeManager" :base-product="baseProduct"/>
        </v-sheet>
      </v-card-text>
    </v-card>
    <v-toolbar class="fixed bottom-0 z-20" color="primary" flat>
      <v-btn dark text @click="close">Inchide</v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-if="stage==='form'" color="#30650a" dark @click="create">
          Creaza si mergi mai departe
          <v-icon class="ml-2">fad fa-arrow-alt-from-left</v-icon>
        </v-btn>
        <v-btn v-if="stage==='attributes'" color="#30650a" dark @click="saveAttributes">
          Salveaza
          <v-icon class="ml-2">fad fa-arrow-alt-from-left</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import Loader from '@/components/Loader'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import HelpIcon from '@/components/HelpIcon'
import BpDescription from '@/views/ProductManagement/BaseProductManager/components/BpDescription'
import DisableableTextField from '@/components/general-form/DisableableTextField'
import BrandSelector from '@/components/autocompletes/BrandSelector'
import AddNewBrandDialog from '@/views/ProductManagement/Brands/Create'
import ImageUploader from '@/components/general-form/ImageUploader'
import BpProductImagesUploader from '@/views/ProductManagement/BaseProductManager/components/BpProductImagesUploader'
import BaseProductAttributeManager from '@/views/ProductManagement/BaseProductManager/BaseProductAttributeManager'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  name: 'BaseProductCreator',
  components: {
    DatePicker,
    BaseProductAttributeManager,
    BpProductImagesUploader,
    ImageUploader,
    AddNewBrandDialog,
    BrandSelector,
    DisableableTextField,
    BpDescription,
    HelpIcon,
    ErrorMessages,
    Loader,
    NewButton
  },
  props: {
    activatorIsOnlyIcon: {
      type: Boolean,
      default: false
    },
    forCategory: {
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      stage: 'form',
      loading: false,
      form: {
        category_id: (this.forCategory ? parseInt(this.forCategory) : ''),
        name: '',
        internal_name: '',
        model: '',
        price: '',
        buying_price: '',
        receiving_at: '',
        stock: '',
        stock_status: '',
        featured_image: false,
        images: [],
        brand_id: '',
        description: ''
      },
      errors: {},
      categories: [],
      baseProduct: false
    }
  },
  computed: {
    statuses () {
      return this.$store.getters['general/productStockStatuses']
    }
  },
  methods: {
    nextStage () {
      this.stage = 'attributes'
    },
    async create () {
      this.loading = true
      try {
        const {data} = await this.$http.post('base-products', this.form)
        this.baseProduct = data
        this.nextStage()
      } catch ({response}) {
        if (response.status === 422) {
          this.errors = response.data.errors
          this.$vs.notify({
            title: 'Eroare',
            text: 'O serie de campuri au erori de validare. Vă rugăm verificați!',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        }
      }
      this.loading = false
    },
    featuredImageUpdated (item) {
      if (item.length > 0) {
        if (item[0].upload.data) {
          this.form.featured_image = item[0].upload.data.id
        }
      } else {
        this.form.featured_image = false
      }
    },
    galleryUpdated (items) {
      if (items.length > 0) {
        this.form.images = items.filter(item => !!item.upload.data).map(item => item.upload.data.id)
      } else {
        this.form.images = []
      }
    },
    close () {
      this.resetFormData()
      this.dialog = false
    },
    resetFormData () {
      this.form = {
        name: '',
        internal_name: '',
        price: '',
        category_id: (this.forCategory ? parseInt(this.forCategory) : ''),
        buying_price: '',
        stock: '',
        stock_status: '',
        featured_image: false,
        images: [],
        brand_id: '',
        description: ''
      }
    },
    loadCategories () {
      return this.$http.get('/product-categories/parents-only')
          .then(({data}) => {
            this.categories = data
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la incarcarea categoriilor! va rugam incercati mai tarziu',
              color: 'danger'
            })
          })

    },
    async saveAttributes () {
      const baseProduct = await this.$refs.attributeManager.save()
      this.$emit('added', baseProduct)
      this.stage = 'form'
      this.close()
    }
  },
  created () {
    this.form.stock_status = this.$store.getters['general/defaultProductStatus']
    this.loadCategories()
  }
}
</script>
