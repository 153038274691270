<template>
  <v-card class="mt-5 bg-grey-light">
    <v-card-title>
      Selecteaza Atribute
    </v-card-title>
    <v-card-text>
      <v-card class="mb-4">
        <v-card-text>
          <v-text-field v-model="filter" hide-details label="Cauta tipul de atribut" outlined/>
        </v-card-text>
      </v-card>
      <template v-if="attrTypes.length">
        <v-expansion-panels>
          <v-expansion-panel v-for="(attributeType, index) in attrTypes" :key="index">
            <v-expansion-panel-header>
              <strong>{{ attributeType.full_name }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="attributeType.values.length" class="mb-5">
                <span v-for="(value, index) in attributeType.values" :key="index">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="isInTheAttributesList(value.id)"
                        class="mx-2 my-1 primary-button"
                        color="success"
                        tile
                        @click="addAttribute(value)"
                      >
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                        {{ value.name }}
                      </v-btn>
                    </template>
                    <span>Adauga</span>
                  </v-tooltip>
                </span>
              </div>
              <AttributeValueCreator :attr-id="attributeType.id" @attrCreated="getAttributeTypes"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-4">
          <v-card-text>
            <v-pagination
              v-model="page"
              :length="paginatedAttrTypes.meta.last_page"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </template>
      <template v-else>
        Nu exista nici un tip de atribut!
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import AttributeValueCreator from '@/views/ProductManagement/Components/components/AttributeValueCreator'

export default {
  name: 'AttributeAndValueSelector',
  components: {
    AttributeValueCreator
  },
  props: {
    attributes: {
      default () {
        return []
      },
      required: true
    }
  },
  data () {
    return {
      filter: '',
      paginatedAttrTypes: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1
        }
      }
    }
  },
  computed: {
    attrTypes () {
      return this.paginatedAttrTypes.data
    },
    page: {
      get () {
        return this.paginatedAttrTypes.meta.current_page
      },
      set (value) {
        this.getAttributeTypes(value)
      }
    }
  },
  methods: {
    addAttribute(value){
      this.$emit('add-attribute', value)
    },
    getAttributeTypes (page) {
      this.$http.get(`product-attributes?append=full_name&include=values,values.attribute_name&count=10&page=${page}&filter[name]=${this.filter}`)
        .then(({data}) => {
          this.paginatedAttrTypes = data
        })
    },
    isInTheAttributesList (currentId) {
      return !!this.attributes.find(item => item.id === currentId)
    }
  },
  created () {
    this.getAttributeTypes()
  },
  watch: {
    filter (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAttributeTypes(1)
      }
    }
  }
}
</script>
