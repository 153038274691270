<template>
  <div>
    <v-btn
      class="ma-2"
      color="primary"
      dark
      @click="dialog2 = !dialog2"
    >
      Adauga document
    </v-btn>
    <v-dialog
      v-model="dialog2"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Dialog 2
        </v-card-title>
        <v-card-text>
          <div class="py-2">
            <v-text-field
              v-model="name"
              dense
              hide-details
              label="Nume document"
              outlined
            ></v-text-field>
            <ErrorMessages :errors="errors.name"/>
          </div>

          <div class="py-2">
            <v-text-field
              v-model="link"
              dense
              hide-details
              label="Link document"
              outlined
            ></v-text-field>
            <ErrorMessages :errors="errors.link"/>
          </div>

          <div class="py-2">
            <v-select
              v-model="type"
              :items="types"
              dense
              hide-details
              label="Tip document"
              outlined
            ></v-select>
            <ErrorMessages :errors="errors.type"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="primary-color"
            text
            @click="closeDialog"
          >
            Anuleaza
          </v-btn>

          <v-spacer/>

          <v-btn
            color="primary"
            dark
            @click="addTechnicalDocuments"
          >
            Adauga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from "@/components/general-form/ErrorMessages";
export default {
  name: "BaseProductTechnicalDocItem",
  components: {ErrorMessages},
  props: {
    baseProductId: {
      required: true
    }
  },
  data() {
    return {
      dialog2: false,
      errors: {},
      types: [
        {text: 'PDF'},
        {text: 'Driver'},
        {text: 'Video'},
      ],
      name: '',
      link: '',
      type: ''
    }
  },
  methods: {
    resetFields() {
      this.link = ''
      this.name = ''
      this.type = ''
    },
    addTechnicalDocuments() {
      this.$store.dispatch('startLoading')
      this.$http.post(`base-products/${this.baseProductId}/links`, {
        base_product_id: this.baseProductId,
        link: this.link,
        name: this.name,
        order: 1,
        type: this.type
      })
        .then(() => {
          this.dialog2 = false
          this.$vs.notify({
            title: 'Success',
            text: 'Documentul a fost adaugat!',
            color: 'success'
          })
          this.$emit('document-added')
        })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    closeDialog() {
      this.dialog2 = false
      this.resetFields()
    }
  }
}
</script>
