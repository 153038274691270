<template>
  <v-tooltip
    :bottom="bottom"
    :left="left"
    :right="right"
    :top="top"
    max-width="350">
    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="mx-2" small>fal fa-question-circle</v-icon>
        </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HelpIcon',
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    left () {
      return this.position === 'left'
    },
    top () {
      return this.position === 'top'
    },
    bottom () {
      return this.position === 'bottom'
    },
    right () {
      return this.position === 'right'
    }
  }
}
</script>

<style scoped>

</style>
