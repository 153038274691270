<template>
  <div>
    <vs-tooltip text="Alege cadou nou">
      <v-btn small @click="openDialog">
        <v-icon class="mr-2" size="xs" x-small>fal fa-plus</v-icon>
        Selecteaza produs nou
      </v-btn>
    </vs-tooltip>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          Alege produsul
        </v-card-title>

        <v-card-text>
          <product-selector v-model="product" return-object/>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="closeDialog ">
            Anuleaza
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="primary-button" @click="select">
            Adauga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ProductSelector from '@/components/autocompletes/ProductSelector'

export default {
  name: 'BaseProductGiftsProductSelector',
  components: {ProductSelector},
  data () {
    return {
      dialog: false,
      product: {}
    }
  },
  methods: {
    select () {
      this.$emit('selected', this.product)
      this.closeDialog()
    },
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.product = {}
    }
  }
}
</script>
