<template>
  <div>
    <vs-tooltip text="Editeaza">
      <v-btn icon @click="openDialog" x-small>
        <v-icon x-small>fal fa-edit</v-icon>
      </v-btn>
    </vs-tooltip>
    <v-dialog
      v-model="dialog2"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Dialog 2
        </v-card-title>
        <v-card-text>
          <div class="py-2">
            <v-text-field
              v-model="name"
              label="Nume document"
              hide-details
              outlined
              dense
            ></v-text-field>
            <ErrorMessages :errors="errors.name" />
          </div>

          <div class="py-2">
            <v-text-field
              v-model="link"
              label="Link document"
              outlined
              hide-details
              dense
            ></v-text-field>
            <ErrorMessages :errors="errors.link" />
          </div>

          <div class="py-2">
            <v-select
              v-model="type"
              :items="types"
              label="Tip document"
              outlined
              dense
              hide-details
            ></v-select>
            <ErrorMessages :errors="errors.type" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="dialog2 = false"
            class="primary-color"
          >
            Anuleaza
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            dark
            @click="save"
          >
            Salveaza
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from "@/components/general-form/ErrorMessages";
export default {
  name: "BaseProductEditTechnicalDocItem",
  components: {ErrorMessages},
  props: {
    baseProductId: {
      required: true
    },
    documentId: {
      required: true
    }
  },
  data() {
    return {
      dialog2: false,
      errors: [],
      types: [
        { text: 'PDF' },
        { text: 'Driver' },
        { text: 'Video' },
      ],
      name: '',
      link: '',
      type: ''
    }
  },
  methods: {
    openDialog() {
      this.dialog2 = true
      this.errors = []
      this.$http.get(`base-products/${this.baseProductId}/links/${this.documentId}`)
      .then(({data}) => {
        this.name = data.name
        this.link = data.link
        this.type = data.type
      })
    },
    save() {
      this.$store.dispatch('startLoading')
      this.$http.patch(`base-products/${this.baseProductId}/links/${this.documentId}`, {
        base_product_id: this.baseProductId,
        link: this.link,
        name: this.name,
        order: 1,
        type: this.type
      })
      .then(() => {
        this.dialog2 = false
        this.$vs.notify({
          title: 'Success',
          text: 'Documentul a fost editat!',
          color: 'success'
        })
        this.$emit('document-edited')
      })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors
          }
        })
      .finally(() => {
        this.$store.dispatch('stopLoading')
      })
    }
  }
}
</script>
